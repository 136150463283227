import { css } from '@emotion/react'

export default css`
    .ant-form-vertical .ant-form-item-label label {
        width: 100%;
    }

    .ant-form-vertical .ant-form-item-label .hint {
        order: 2;
        margin-left: 0.4rem;
    }

    label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        order: 1;
        margin-inline-end: 0 !important;
        margin-inline-start: 4px;
    }

    label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
        display: none;
    }

    .ant-form-vertical .ant-form-item-label,
    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label {
        padding-bottom: 2px;
    }

    .ant-form-item-label > label {
        font-size: 0.875em !important;
    }

    .ant-input-number-affix-wrapper {
        width: 100%;
    }

    .label-card {
        background: #3d3743;
    }

    .item-form-label {
        display: flex;
        margin-bottom: 5px !important;
    }

    .ant-form-vertical .ant-form-item.item-form-label .ant-form-item-control {
        width: 25%;
    }

    .item-form-label .ant-form-item-control {
        align-items: end;
    }

    .label-form-container .ant-form-vertical .ant-form-item-row {
        flex-direction: inherit;
        width: 100%;
    }

    .label-form-container .label-under-questions > .ant-space-item:last-child {
        width: 100%;
    }
`
