import React from 'react'
import { Space } from 'antd'
import { spacing } from '@/styles/theme'
import { ReactComponent as IconLinkedin } from '@assets/icon/linkedin.svg'
import { ReactComponent as IconTwitter } from '@assets/icon/twitter.svg'
import { ReactComponent as IconInstagram } from '@assets/icon/instagram.svg'

const FooterSocials = () => {
    return (
        <Space size={spacing(1)}>
            <a target={'_blank'} href="https://www.linkedin.com/company/ecoprod/" rel="noreferrer">
                <IconLinkedin />
            </a>
            <a target={'_blank'} href="https://twitter.com/ecoprod_france" rel="noreferrer">
                <IconTwitter />
            </a>
            <a target={'_blank'} href="https://www.instagram.com/ecoprodfrance/" rel="noreferrer">
                <IconInstagram />
            </a>
        </Space>
    )
}

export default FooterSocials
