import { css } from '@emotion/react'

export default css`
    .ant-avatar {
        display: inline-grid;
        place-items: center;
    }

    .ant-avatar.ant-avatar-square {
        border-radius: 25%;
    }
`
